<template>
  <b-overlay :show="loading">
    <b-card no-body>
      <b-card-body>
        <b-card-title>
          <b-row>
            <b-col md="2">
              <div align="left">
                <b-button
                  variant="outline-primary"
                  v-b-modal.modal-sm
                  :to="{ name: 'admin-universitas' }"
                  class="btn-icon"
                >
                  <feather-icon icon="ArrowLeftIcon" class="mr-25" />Kembali</b-button
                >
              </div>
            </b-col>
            <b-col md="8">
              <div align="center">{{ univDataId.name }}</div>
            </b-col>
            <b-col md="2">
              <div align="right">
                <b-button
                  variant="outline-primary"
                  v-b-modal.modal-sm
                  @click="ModalShow()"
                  class="btn-icon"
                >
                  <feather-icon icon="PlusIcon" class="mr-25" />Tambah</b-button
                >
              </div>
              <!-- Modal CRUD -->
              <b-modal
                v-model="Modal"
                id="modal-tambah"
                centered
                size="sm"
                :title="titleModal"
              >
                <b-card-text>
                  <b-form>
                    <b-form-group label="Rumpun" label-for="Rumpun">
                      <b-form-select v-model="form.rumpun_id" :options="rumpunData" />
                    </b-form-group>
                    <b-form-group label="Fakultas/Jurusan" label-for="jurusan">
                      <b-form-input
                        id="jurusan"
                        v-model="form.name"
                        placeholder="Fakultas/Jurusan"
                      />
                    </b-form-group>
                    <b-form-group
                      label="Passing Grade (ex: 208.5)"
                      label-for="passing_grade"
                    >
                      <b-form-input
                        id="passing_grade"
                        v-model="form.passing_grade"
                        placeholder="Passing Grade"
                        type="number"
                      />
                    </b-form-group>
                    <b-form-group
                      label="Passing Grade Persentase (Khusus rasionalisasi mandiri) (ex: 208.5)"
                      label-for="passing_grade"
                    >
                      <b-form-input
                        id="passing_grade"
                        v-model="form.passing_grade_percentage"
                        placeholder="Passing Grade: 120%"
                        type="number"
                      />
                    </b-form-group>
                    <b-form-group label="Daya Tampung Jurusan" label-for="daya_tampung">
                      <b-form-input
                        id="daya_tampung"
                        v-model="form.daya_tampung"
                        placeholder="Daya Tampung"
                        type="number"
                      />
                    </b-form-group>
                    <b-form-group label="Peminat Jurusan" label-for="peminat">
                      <b-form-input
                        id="daya_tampung"
                        v-model="form.peminat"
                        placeholder="Jumlah Peminat"
                        type="number"
                      />
                    </b-form-group>
                  </b-form>
                </b-card-text>

                <template #modal-footer>
                  <div class="w-100">
                    <p class="float-left mb-0"></p>

                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      type="submit"
                      variant="outline-success"
                      class="mr-1 btn-icon"
                      @click.prevent="Tambah"
                    >
                      <feather-icon icon="SaveIcon" class="mr-25" />{{
                        label || "Tambah"
                      }}
                    </b-button>
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="outline-primary"
                      class="float-right btn-icon"
                      @click.prevent="tutupModal"
                    >
                      <feather-icon icon="LogOutIcon" class="mr-25" />Tutup
                    </b-button>
                  </div>
                </template>
              </b-modal>
            </b-col></b-row
          >
        </b-card-title>
        <b-card-text class="blog-content-truncate">
          <b-row>
            <b-col md="3" sm="4" class="my-1">
              <b-form-group class="mb-0">
                <label class="d-inline-block text-sm-left mr-50">Per page</label>
                <b-form-select
                  id="perPageSelect"
                  v-model="perPage"
                  size="sm"
                  :options="pageOptions"
                  class="w-50"
                />
              </b-form-group>
            </b-col>
            <b-col md="4" sm="8" class="my-1">
              <b-form-group
                label="Sort"
                label-cols-sm="2"
                label-align-sm="right"
                label-size="sm"
                label-for="sortBySelect"
                class="mb-0"
              >
                <b-input-group size="sm">
                  <b-form-select
                    id="sortBySelect"
                    v-model="sortBy"
                    :options="sortOptions"
                    class="w-50"
                  >
                    <template v-slot:first>
                      <option value="">-- none --</option>
                    </template>
                  </b-form-select>
                  <b-form-select
                    v-model="sortDesc"
                    size="sm"
                    :disabled="!sortBy"
                    class="w-30"
                  >
                    <option :value="false">Asc</option>
                    <option :value="true">Desc</option>
                  </b-form-select>
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col md="5" class="my-1">
              <b-form-group label-for="filterInput" class="mb-0">
                <b-input-group size="sm">
                  <b-form-input
                    id="filterInput"
                    v-model="filter"
                    type="search"
                    placeholder="Search......"
                  />
                  <b-input-group-append>
                    <b-button :disabled="!filter" @click="filter = ''"> Clear </b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </b-col>
            <!-- <b-col md="2" class="my-1">
              <b-button variant="primary" size="sm"
                ><feather-icon icon="TrashIcon" />Multiple Delete</b-button
              >
            </b-col> -->

            <b-col cols="12">
              <b-table
                sticky-header="true"
                striped
                small
                hover
                responsive
                :per-page="perPage"
                :current-page="currentPage"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :sort-direction="sortDirection"
                :filter="filter"
                :filter-included-fields="filterOn"
                @filtered="onFiltered"
                :fields="fields"
                :items="jurusanData"
              >
                <!-- A virtual column -->
                <template #cell(index)="data">
                  {{ data.index + 1 }}
                </template>
                

                <!-- A custom formatted column -->
                <template #cell(action)="row">
                  <div v-if="jurusanData.length == 1">
                  <b-dropdown variant="link" toggle-class="text-decoration-none" no-caret>
                    <template v-slot:button-content>
                      <feather-icon
                        icon="MoreVerticalIcon"
                        size="16"
                        class="text-body align-middle mr-25"
                          style="height: 100px"
                      />
                    </template>
                    <b-dropdown-item @click="viewDetail(row.item)">
                      <feather-icon icon="EyeIcon" class="mr-50" />
                      <span>Detail</span>
                    </b-dropdown-item>
                    <b-dropdown-item @click="showMapelPendukung(row.item)">
                      <feather-icon icon="EditIcon" class="mr-50" />
                      <span>Mapel Pendukung</span>
                    </b-dropdown-item>
                    <b-dropdown-item @click="ModalUbah(row.item)">
                      <feather-icon icon="Edit2Icon" class="mr-50" />
                      <span>Edit</span>
                    </b-dropdown-item>
                    <b-dropdown-item @click="ModalHapus(row.item)">
                      <feather-icon icon="TrashIcon" class="mr-50" />
                      <span>Hapus</span>
                    </b-dropdown-item>
                  </b-dropdown>
                  </div>
                  <div v-if="jurusanData.length != 1">
                  <b-dropdown variant="link" toggle-class="text-decoration-none" no-caret>
                    <template v-slot:button-content>
                      <feather-icon
                        icon="MoreVerticalIcon"
                        size="16"
                        class="text-body align-middle mr-25"
                      />
                    </template>
                    <b-dropdown-item @click="showMapelPendukung(row.item)">
                      <feather-icon icon="EditIcon" class="mr-50" />
                      <span>Mapel Pendukung</span>
                    </b-dropdown-item>
                    <b-dropdown-item @click="ModalUbah(row.item)">
                      <feather-icon icon="Edit2Icon" class="mr-50" />
                      <span>Edit</span>
                    </b-dropdown-item>
                    <b-dropdown-item @click="ModalHapus(row.item)">
                      <feather-icon icon="TrashIcon" class="mr-50" />
                      <span>Hapus</span>
                    </b-dropdown-item>
                  </b-dropdown>
                  </div>
                </template>

                <!-- A custom formatted column -->
                <template #cell(Popularity)="data">
                  <b-progress
                    :value="data.value.value"
                    max="100"
                    :variant="data.value.variant"
                    striped
                  />
                </template>

                <template #cell(color)="data">
                  <b-badge :variant="data.item.color">
                    {{ data.item.color }}
                  </b-badge>
                </template>

                <!-- A virtual composite column -->
                <template #cell(price)="data">
                  {{ "$" + data.value }}
                </template>

                <!-- Optional default data cell scoped slot -->
                <template #cell()="data">
                  {{ data.value }}
                </template>
              </b-table>

              <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                align="center"
                size="sm"
                class="my-0"
              />
            </b-col>
          </b-row>
        </b-card-text>
      </b-card-body>
    </b-card>

    <!-- Modal Mapel Pendukung -->
    <b-modal
      id="modal-mapel-pendukung"
      centered
      size="md"
      title="Daftar Mapel Pendukung"
      hide-footer
    >
      <section class="mb-3">
        <p class="mb-2"><strong>Tambah Mapel Pendukung:</strong></p>
        <b-row class="flex items-center">
          <b-col sm="12" md="8" class="mb-2 mb-md-0">
            <v-select
              :reduce="(option) => option.id"
              v-model="mpPendukung.selectedMapel"
              :options="mapels"
              label="nama_mapel"
            ></v-select>
          </b-col>
          <b-col sm="12" md="4">
            <b-button @click.prevent="updateMpPendukung" variant="primary"
              >Tambahkan</b-button
            >
          </b-col>
        </b-row>
      </section>
      <b-table striped responsive :fields="mpPendukung.fields" :items="mpPendukung.data">
        <template #cell(index)="row">
          {{ row.index + 1 }}
        </template>
        <template #cell(action)="{ item }">
          <feather-icon
            icon="TrashIcon"
            @click.prevent="deleteMapelPendukung(item.id)"
            class="cursor-pointer text-danger"
          ></feather-icon>
        </template>
      </b-table>
    </b-modal>
    <!-- / -->

    <!-- Modal detail jurusan -->
    <b-modal
      id="modal-info"
      size="md"
      title="Info Jurusan"
      hide-footer
    >
      <h4 class="mb-2">
        <strong>{{ currentMajor?.name }}</strong>
      </h4>
      <table class="table table-bordered w-100">
        <tr>
          <th class="text-center">Universitas</th>
          <td class="text-center">:</td>
          <td class="text-center">{{ currentMajor?.school?.name }}</td>
        </tr>
        <tr>
          <th class="text-center">Rumpun Jurusan</th>
          <td class="text-center">:</td>
          <td class="text-center">{{ currentMajor?.rumpun?.name }}</td>
        </tr>
        <tr>
          <th class="text-center">Daya Tampung</th>
          <td class="text-center">:</td>
          <td class="text-center">{{ currentMajor?.daya_tampung }}</td>
        </tr>
        <tr>
          <th class="text-center">Peminat</th>
          <td class="text-center">:</td>
          <td class="text-center">{{ currentMajor?.peminat }}</td>
        </tr>
        <tr>
          <th class="text-center">Keketatan</th>
          <td class="text-center">:</td>
          <td class="text-center">{{ currentMajor?.keketatan }}</td>
        </tr>
        <tr>
          <th class="text-center">Passing Grade</th>
          <td class="text-center">:</td>
          <td class="text-center">{{ currentMajor?.passing_grade }}</td>
        </tr>
        <tr>
          <th class="text-center">Passing Grade Persentase (Khusus Mandiri)</th>
          <td class="text-center">:</td>
          <td class="text-center">{{ currentMajor?.passing_grade_percentage }}</td>
        </tr>
      </table>
    </b-modal>
    <!-- / -->
  </b-overlay>
</template>

<script>
import vSelect from "vue-select";
import {
  BRow,
  BOverlay,
  BCol,
  BTable,
  BCard,
  BCardText,
  BCardTitle,
  BCardBody,
  BPagination,
  BProgress,
  BBadge,
  BButton,
  BModal,
  VBModal,
  BForm,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BInputGroup,
  BInputGroupAppend,
  BFormRadioGroup,
  VBTooltip,
  BDropdownItem,
  BDropdown,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BRow,
    BOverlay,
    vSelect,
    BCol,
    BCard,
    BCardText,
    BCardTitle,
    BCardBody,
    BTable,
    BPagination,
    BProgress,
    BBadge,
    BButton,
    BModal,
    VBModal,
    BForm,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BInputGroup,
    BInputGroupAppend,
    BFormRadioGroup,
    VBTooltip,
    ToastificationContent,
    BDropdownItem,
    BDropdown,
  },
  directives: {
    "b-modal": VBModal,
    "b-tooltip": VBTooltip,
    Ripple,
  },
  data() {
    return {
      currentMajor: null,
      loading: false,
      mapels: [],
      mpPendukung: {
        data: [{ name: "Matematika" }, { name: "Fisika" }],
        fields: [
          { key: "index", label: "No" },
          { key: "nama_mapel", label: "Mata Pelajaran" },
          { key: "action", label: "#" },
        ],
        selectedMapel: null,
      },
      id: null,
      activeAction: null,
      label: "Simpan",
      //Data
      rumpunData: [],
      jurusanData: [],
      univDataId: {
        name: null,
      },
      form: {
        id: null,
        name: null,
        rumpun_id: null,
        passing_grade: 0,
        passing_grade_percentage: 0,
        peminat: 0,
        daya_tampung: 0,
      },
      //Modal
      titleModal: null,
      Modal: false,
      ModalEdit: false,
      //Table
      fields: [
        { key: "index", label: "No" },
        { key: "name", label: "Jurusan", sortable: true },
        { key: "passing_grade", label: "Passing Grade", sortable: true },
        { key: "passing_grade_percentage", label: "Persentase PG (Mandiri)", sortable: true },
        { key: "daya_tampung", label: "Daya Tampung", sortable: true },
        { key: "peminat", label: "Peminat", sortable: true },
        { key: "keketatan", label: "Keketatan", sortable: true },
        { key: "action", label: "#" },
      ],
      selected: [],
      total: [],
      record: [],
      optFilter: [
        { id: "name", value: "Fakultas/Jurusan" },
        // { id: "nip", value: "NIP" },
      ],
      perPage: 10,
      pageOptions: [10, 30, 50],
      totalRows: 1,
      currentPage: 1,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      // filter: "Fakultas/Jurusan",
      search: null,
      idd: null,
      currentMajorId: null,
    };
  },
  watch: {},
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.label, value: f.key }));
    },
  },
  methods: {
    viewDetail(jurusan) {
      this.currentMajor = jurusan
      this.$bvModal.show("modal-info");
    },
    async deleteMapelPendukung(id) {
      const payload = {
        id,
        fungsi: 2,
      };

      try {
        this.loading = true;
        this.$bvModal.hide("modal-mapel-pendukung");

        await this.$store.dispatch("jurusan/saveMapelPendukung", [payload]);
        await this.getMapelPendukung(this.currentMajorId);
        await this.getDataJurusan();

        this.loading = false;
        this.$bvModal.show("modal-mapel-pendukung");
      } catch (e) {
        this.loading = false;
        this.$bvModal.show("modal-mapel-pendukung");
        this.displayError(e);
        return false;
      }
    },
    async getMapelPendukung(majorId) {
      const params = {
        major_id: majorId,
      };

      try {
        const response = await this.$store.dispatch(
          "jurusan/indexMapelPendukung",
          params
        );
        if (response.data?.data) {
          this.mpPendukung.data = response.data.data;
        }
      } catch (e) {
        this.displayError(e);
        return false;
      }
    },
    async updateMpPendukung() {
      const payload = {
        mapel_id: this.mpPendukung.selectedMapel,
        major_id: this.currentMajorId,
      };

      try {
        this.loading = true;

        this.$bvModal.hide("modal-mapel-pendukung");
        const response = await this.$store.dispatch("jurusan/saveMapelPendukung", [
          payload,
        ]);
        await this.getMapelPendukung(this.currentMajorId);
        await this.getDataJurusan();
        this.displaySuccess({
          message: response.data
            ? response.data.message
            : "Mapel pendukung berhasil diperbaharui",
        });
        this.loading = false;
        this.$bvModal.show("modal-mapel-pendukung");
      } catch (e) {
        this.loading = false;
        this.$bvModal.show("modal-mapel-pendukung");
        this.displayError(e);
        return false;
      }
    },
    async getMapels() {
      let mapels = await this.$store.dispatch("mapel/index");
      const okResponse = mapels.status === 200;

      if (okResponse) {
        this.mapels = mapels.data.data;
      }
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    tutupModal() {
      this.Modal = false;
      this.resetForm();
      this.getDataJurusan();
    },
    resetForm() {
      this.form = {
        id: null,
        name: null,
        rumpun_id: null,
        passing_grade: null,
      };
    },
    ModalShow() {
      this.id = null;
      this.activeAction = "tambah";
      this.titleModal = "Tambah Fakultas/Jurusan";
      this.Modal = true;
      // this.ModalEdit = false;
    },
    showMapelPendukung(item) {
      this.mpPendukung.data = item.mapel_pendukung;
      this.currentMajorId = item.id;
      this.$bvModal.show("modal-mapel-pendukung");
    },
    ModalUbah(item) {
      this.id = item.id;
      this.form = item;
      this.activeAction = "ubah";
      this.titleModal = "Ubah Fakultas/Jurusan";
      this.Modal = true;
      // this.ModalEdit = true;
    },
    ModalHapus(data) {
      this.$swal({
        title: "Anda Yakin?",
        text: "Data tidak bisa dikembalikan!",
        icon: "warning",
        showCancelButton: true,
        cancelButtonText: "Batal",
        confirmButtonText: "Ya, Hapus!",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          data.fungsi = 2; //soft delete
          this.$store
            .dispatch("jurusan/createUpdateDelete", [data])
            .then(() => {
              this.getDataJurusan();
              this.displaySuccess({
                text: "Data berhasil dihapus",
              });
            })
            .catch((e) => {
              this.displayError(e);
              return false;
            });
          this.$swal({
            icon: "success",
            title: "Terhapus!",
            text: "Data berhasil dihapus.",
            // timer: 1000,
            allowOutsideClick: true,
            customClass: {
              confirmButton: "btn btn-success",
            },
            buttonsStyling: false,
          });
        } else if (result.dismiss === "cancel") {
          this.$swal({
            title: "Batal",
            text: "Data aman, kamu batal menghapus data :)",
            icon: "error",
            // timer: 1000,
            allowOutsideClick: true,
            customClass: {
              confirmButton: "btn btn-success",
            },
            buttonsStyling: false,
          });
        }
      });
    },
    pesanGagal() {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: "Notifikasi",
            icon: "CheckIcon",
            text: "Gagal menyimpan data, harap cek kembali data yang diisi",
            variant: "warning",
          },
        },
        {
          position: "bottom-right",
        }
      );
    },
    Tambah() {
      if (this.form.name == null || this.form.name == "") {
        this.pesanGagal();
        return false;
      }
      let payload = {
        name: this.form.name,
        school_id: this.$route.params.id,
        rumpun_id: this.form.rumpun_id,
        passing_grade: this.form.passing_grade,
        passing_grade_percentage: this.form.passing_grade_percentage,
        daya_tampung: this.form.daya_tampung,
        peminat: this.form.peminat,
      };

      if (this.id) {
        payload.id = this.id;
      }

      this.$store
        .dispatch("jurusan/createUpdateDelete", [payload])
        .then(() => {
          if (this.activeAction == "tambah") {
            this.id = null;
          }
          this.resetForm();
          this.Modal = false;
          // this.ModalEdit = false;
          this.getDataJurusan();
          this.displaySuccess({
            text: "Fakultas/Jurusan berhasil di" + this.activeAction,
          });
        })
        .catch((e) => {
          this.displayError(e);
          return false;
        });
    },
    async getDataJurusan() {
      const payload = {
        // sekolah_id: localStorage.getItem("sekolah"),
        // filter: this.filter !== null ? this.filter.id : "name",
        // search: this.search,
        // start: (this.currentPage - 1) * this.itemsPerPage,
        // length: this.itemsPerPage,
        school_id: this.$route.params.id,
      };
      this.$store
        .dispatch("jurusan/index", payload)
        .then((response) => {
          let jurusanData = response.data.data;
          this.jurusanData = jurusanData;
          this.totalRows = this.jurusanData.length;
        })
        .catch((error) => {
          this.$root.$emit("errorData", error);
        });
    },
    async getDataById() {
      this.$store
        .dispatch("masterUniv/indexId", this.$route.params.id)
        .then((response) => {
          let univDataId = response.data;
          this.univDataId = univDataId;
        })
        .catch((error) => {
          this.$root.$emit("errorData", error);
        });
    },

    getDataRumpun() {
      this.loading = true;
      const payload = {
        // sekolah_id: localStorage.getItem("sekolah"),
        // filter: this.filter !== null ? this.filter.id : "name",
        // search: this.search,
        // start: (this.currentPage - 1) * this.itemsPerPage,
        // length: this.itemsPerPage,
        // type: "guide",
      };
      this.$store
        .dispatch("rumpun/index", payload)
        .then((response) => {
          this.loading = false;
          let rumpunData = response.data.data;
          rumpunData.unshift({ id: null, name: "- Pilih Rumpun -" });
          rumpunData.map((item) => {
            item.value = item.id;
            item.text = item.name;
          });

          this.rumpunData = rumpunData;
        })
        .catch((error) => {
          this.loading = false;
          this.$root.$emit("errorData", error);
        });
    },
  },
  created() {
    this.getMapels();
  },
  mounted() {
    this.getDataRumpun();
    this.getDataJurusan();
    this.getDataById();
  },
};
</script>

<style>
.dropdown-menu {
  max-height: 1000px;
  overflow: scroll;
}
</style>
